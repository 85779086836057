import React, { Component } from "react";
import { connect } from "react-redux";
import {
  NavLink,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";

import { QrCodeScan, ListCheck } from "react-bootstrap-icons";

import logo from "./OXEX-RGB-whiteout.svg";
import HomePage from "./HomePage";
import ScannerPage from "./ScannerPage";
import HistoryPage from "./HistoryPage";

class App extends Component {
  render() {
    return (
      <Router>
        <nav className="navbar navbar-light bg-primary shadow py-3">
          <img
            src={logo}
            className="bg-primary mx-auto"
            alt="OXEX logo"
            style={{ width: "120px", height: "auto" }}
          />
        </nav>
        <div className="container mb-5 pb-5">
          <div className="w-100 container-fluid bg-white fixed-bottom shadow">
            <div className="row">
              <NavLink
                to="/scanner"
                activeClassName="bg-primary text-white"
                className="col py-2"
              >
                <h3 className="mb-1 text-center">
                  <QrCodeScan size="30" />
                </h3>
                <p className="mb-0 text-center">Scan</p>
              </NavLink>
              <NavLink
                to="/history"
                activeClassName="bg-primary text-white"
                className="col py-2"
              >
                <h3 className="mb-1 text-center">
                  <ListCheck size="30" />
                </h3>
                <p className="mb-0 text-center">History</p>
              </NavLink>
            </div>
          </div>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/scanner" component={ScannerPage} />
            <Route path="/go/:profile_id" component={ScannerPage} />
            <Route path="/history" component={HistoryPage} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default connect()(App);
