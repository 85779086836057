import axios from "axios";
import { API_BASE, TICKET_ERROR, TICKET_RETURNED } from "./types";

export const get_ticket = (ticket_id, profile_id) => (dispatch) => {
  // Get ticket from the API using the ID
  const config = {
    headers: {
      "x-api-token": profile_id,
      "Access-Control-Allow-Origin": "*",
    },
  };

  axios
    .get(
      API_BASE + `/api/scanner/authenticate/${encodeURIComponent(ticket_id)}`,
      config
    )
    .then((res) => {
      dispatch({
        type: TICKET_RETURNED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: TICKET_ERROR,
        payload: null,
      });
    });
};
