import {
  START_PROFILE_SCAN,
  SET_PROFILE_ID,
  PROFILE_RETURNED,
  PROFILE_ERROR,
  CLEAR_PROFILE,
} from '../actions/types.js';

const initialState = {
  profile_scanning: false,
  profile_id: '',
  loading_profile: false,
  display_profile: false,
  profile_error: false,
  current_profile: {},
};

export default function profile (state = initialState, action) {
  switch (action.type) {
    case START_PROFILE_SCAN:
      return {
        ...state,
        profile_scanning: true,
        display_profile: false,
      }
    case SET_PROFILE_ID:
      return {
        ...state,
        profile_scanning: false,
        profile_id: action.payload,
        loading_profile: true,
        display_profile: false,
      };
    case PROFILE_RETURNED:
      return {
        ...state,
        loading_profile: false,
        display_profile: true,
        current_profile: action.payload,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        loading_profile: false,
        display_profile: false,
        profile_error: true,
        current_profile: {},
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile_scanning: false,
        profile_id: '',
        loading_profile: false,
        display_profile: false,
        profile_error: false,
        current_profile: {},
      };
    default:
      return state;
  }
}
