import React from "react";
import PropTypes from "prop-types";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

export default function ScanTimeAgo(props) {
  return <ReactTimeAgo {...props} component={Time} />;
}

function Time({ date, verboseDate, tooltip, children, ...rest }) {
  return <span>{children}</span>;
}

Time.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  verboseDate: PropTypes.string,
  tooltip: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};
