import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { get_history } from "../actions/history";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBan } from "@fortawesome/free-solid-svg-icons";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

class HistoryPage extends Component {
  componentDidMount() {
    if (this.props.display_profile) {
      this.props.get_history(this.props.profile_id);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.display_profile) {
      if (this.props.scanned_id !== prevProps.scanned_id) {
        this.props.get_history(this.props.profile_id);
      }
    }
  }

  render() {
    let history_display;
    if (this.props.display_profile && this.props.display_history) {
      history_display = this.props.history.reverse().map((ticket) => (
        <Fragment>
          <div className="card bg-white rounded-sm shadow-sm p-3 mx-auto">
            <div className="row">
              <div className="col-auto">
                <span
                  className="text-center d-block"
                  style={{ fontSize: "1.8em" }}
                >
                  {ticket.valid ? (
                    <FontAwesomeIcon
                      className="text-success"
                      icon={faCheck}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faBan}
                    ></FontAwesomeIcon>
                  )}
                </span>
              </div>
              <div className="col-auto my-2">
                <span className="text-muted">{ticket.time.split("T")[1]}</span>
              </div>
              <div className="col my-2">
                <span className="">{ticket.name}</span>
              </div>
            </div>
            {ticket.message && (
              <div className="row">
                <div className="col text-left small text-muted">
                  {ticket.message}
                </div>
              </div>
            )}
          </div>
          <br />
        </Fragment>
      ));
    } else if (this.props.display_profile && !this.props.display_history) {
      history_display = (
        <div className="d-flex justify-content-center">
          <div className="spinner-border m-4" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      history_display = (
        <Fragment>
          <p>
            <b>No event profile is active.</b> Scan a setup QR Code or visit the
            event setup link to continue.
          </p>
        </Fragment>
      );
    }
    return (
      <div className="container">
        {this.props.display_history && (
          <Fragment>
            <div className="row">
              <div className="col bg-white text-center px-3 pt-2 mx-3 rounded-sm shadow-sm">
                <span className="d-block">
                  Total Scans at {this.props.profile.name}:
                </span>
                <span className="d-block" style={{ fontSize: "32px" }}>
                  {this.props.total_scanned}
                </span>
              </div>
            </div>
            <br />
          </Fragment>
        )}
        {history_display}
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scanned_id: state.scanner.scanned_id,
  display_profile: state.profile.display_profile,
  profile: state.profile.current_profile,
  profile_id: state.profile.profile_id,
  history: state.history.history,
  total_scanned: state.history.total_scanned,
  display_history: state.history.display_history,
});

export default connect(mapStateToProps, { get_history })(HistoryPage);
