import {HISTORY_RETURNED} from '../actions/types.js';

const initialState = {
  history: [],
  total_scanned: 0,
  display_history: false,
}

export default function history (state = initialState, action) {
  switch(action.type) {
    case HISTORY_RETURNED:
      return {
        ...state,
        history: action.payload.recents,
        total_scanned: action.payload.scannedTotal,
        display_history: true,
      }
    default:
      return state;
  }
}