import React from "react";
import { Link } from "react-router-dom";
import logo from "./OXEX-RGB.svg";
const HomePage = () => {
  return (
    <div className="container h-100">
      <div className="mt-5 d-flex justify-content-center">
        <img
          src={logo}
          className="mt-5 mx-auto"
          alt="OXEX logo"
          style={{ width: "200px", height: "auto" }}
        />
      </div>
      <div className="mt-5">
        <h1 className="text-center font-weight-light">Ticket Scanner</h1>
      </div>
      <div className="mt-5">
        <p className="text-center">
          Scan a setup QR Code or visit the event setup link to continue.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
