import React, { Component, Fragment } from "react";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";
import store from "../store";
import { get_ticket } from "../actions/scanner";
import { get_profile } from "../actions/profile";
import { SET_PROFILE_ID, SET_SCAN, CLEAR_SCAN } from "../actions/types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faBan,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import TimeAgo from "../components/TimeAgo";
const QrReaderStyle = {
  width: "100%",
  border: "3px solid black",
};

class ScannerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_form_value: "",
    };
  }

  componentDidMount() {
    if (this.props.match.params.profile_id) {
      store.dispatch({
        type: SET_PROFILE_ID,
        payload: this.props.match.params.profile_id,
      });
      this.props.get_profile(this.props.match.params.profile_id);
    }
  }

  handleLoad = (onLoadObject) => {};

  handleScan = (id) => {
    if (id) {
      store.dispatch({
        type: SET_SCAN,
        payload: id,
      });
      this.props.get_ticket(id, this.props.profile_id);
    }
  };

  handleError = (err) => {};

  handleClearScan = () => {
    store.dispatch({
      type: CLEAR_SCAN,
      payload: null,
    });
  };

  handleIDFormUpdate = (event) => {
    this.setState({ id_form_value: event.target.value.toUpperCase() });
  };

  handleIDFormSubmit = (event) => {
    store.dispatch({
      type: SET_SCAN,
      payload: this.state.id_form_value,
    });
    this.props.get_ticket(this.state.id_form_value, this.props.profile_id);
    // event.preventDefault();
  };

  render() {
    // SET UP TICKET DISPLAY
    let ticket_display;
    let border_color;
    if (this.props.display_ticket) {
      if (this.props.current_ticket.noTicket) {
        border_color = "red";
        ticket_display = (
          <Fragment>
            <div className="mt-5 mb-3">
              <h1 className="text-center">
                <FontAwesomeIcon
                  className="text-danger"
                  icon={faBan}
                ></FontAwesomeIcon>
              </h1>
              <h3 className="text-center mb-4">Ticket Not Found</h3>
              <p className="text-center text-muted mb-4">
                <FontAwesomeIcon
                  className="text-warning"
                  icon={faExclamationTriangle}
                ></FontAwesomeIcon>
                <br />
                Attempted forgery of an OXEX QR Code.
              </p>

              <button
                className="btn btn-primary"
                style={{ width: "100%" }}
                onClick={() => this.handleClearScan()}
              >
                <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
              </button>
            </div>
          </Fragment>
        );
      } else {
        let ticket = this.props.current_ticket;
        let ticket_data = this.props.current_ticket.ticket;

        if (ticket.allowEntry) {
          border_color = "green";
        } else {
          border_color = "red";
        }

        ticket_display = (
          <Fragment>
            {ticket.message.length > 0 && (
              <div className="row mb-3">
                <div className="col">
                  <span className="text-center text-muted small d-block">
                    Message
                  </span>
                  {ticket.message.map((msg) => (
                    <span key={msg} className="text-center d-block">
                      {msg}
                    </span>
                  ))}
                </div>
              </div>
            )}

            <div className="row mb-3">
              <div className="col">
                <span className="text-center text-muted small d-block">
                  Ticket ID
                </span>
                <span className="text-center d-block">
                  {ticket_data.friendlyID}
                </span>
              </div>
              <div className="col">
                <span className="text-center text-muted small d-block">
                  Name
                </span>
                <span className="text-center d-block">{ticket_data.name}</span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span className="text-center text-muted small d-block">
                  Allow Entry
                </span>
                <span
                  className="text-center d-block"
                  style={{ fontSize: "1.8em" }}
                >
                  {ticket.allowEntry ? (
                    <FontAwesomeIcon
                      className="text-success"
                      icon={faCheck}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faBan}
                    ></FontAwesomeIcon>
                  )}
                </span>
              </div>
              <div className="col">
                <span className="text-center text-muted small d-block">
                  Scan Number
                </span>
                <span
                  className="text-center d-block"
                  style={{ fontSize: "1.8em" }}
                >
                  {ticket.previousScans.length + 1}
                </span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span className="text-center text-muted small d-block">
                  Ticket Type
                </span>
                <span className="text-center d-block">
                  {ticket_data.accessTypeName}
                </span>
              </div>
              <div className="col">
                <span className="text-center text-muted small d-block">
                  Add-ons
                </span>
                <span className="text-center d-block">
                  {" "}
                  {ticket_data.options.length > 0
                    ? ticket_data.options.join(", ")
                    : "None"}
                </span>
              </div>
            </div>
            {ticket_data.signupAttributes > 0 && (
              <div className="row mb-3">
                <div className="col">
                  <span className="text-center text-muted small d-block">
                    {ticket_data.additionalFieldName}
                  </span>
                  <span className="text-center d-block">
                    {ticket_data.signupAttributes}
                  </span>
                </div>
              </div>
            )}

            {ticket_data.principalTicketholderName && (
              <div className="row mb-3">
                <div className="col">
                  <span className="text-center text-muted small d-block">
                    Guest of
                  </span>
                  <span className="text-center d-block">
                    {ticket_data.principalTicketholderName}
                  </span>
                </div>
              </div>
            )}

            {ticket_data.photoID && (
              <Fragment>
                <div className="row mb-3">
                  <div className="col-auto mx-auto">
                    <img
                      src={ticket_data.photoID}
                      style={{ width: "200px", height: "240px" }}
                      alt="ID"
                    />
                  </div>
                </div>
              </Fragment>
            )}

            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => this.handleClearScan()}
            >
              <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
            </button>
            {ticket.previousScans.length > 0 && (
              <div className="row mt-3">
                <div className="col">
                  <span className="text-center text-muted small d-block">
                    Previous Scans
                  </span>
                  <div>
                    {ticket.previousScans.map((item, i) => (
                      <span key={i} className="d-block">
                        {new Date(item.time).toLocaleTimeString()} at{" "}
                        {item.gateName}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        );
      }
    } else if (this.props.loading) {
      border_color = "white";
      ticket_display = (
        <div className="d-flex justify-content-center">
          <div className="spinner-border m-4" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      ticket_display = (
        <div className="d-flex justify-content-center">
          We encountered an error. Check your internet connection.
        </div>
      );
    }

    // SET UP SCANNER DISPLAY
    let scanner_display;
    if (this.props.display_ticket || this.props.loading) {
      scanner_display = "";
    } else if (this.props.display_profile) {
      scanner_display = (
        <Fragment>
          <h5 className="mt-3">Scan QR Code</h5>
          <QrReader
            delay={1000}
            onError={this.handleError}
            onLoad={this.handleLoad}
            showViewFinder={true}
            onScan={this.handleScan}
            style={QrReaderStyle}
            facingMode="environment"
          />
          <br />
          <form onSubmit={() => this.handleIDFormSubmit()}>
            <h5>Input Ticket ID</h5>
            <div className="input-group mb-3">
              <input
                value={this.state.id_form_value}
                onChange={(e) => this.handleIDFormUpdate(e)}
                className="form-control"
                maxLength={8}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={this.state.id_form_value.length != 8}
                >
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                </button>
              </div>
            </div>
          </form>
        </Fragment>
      );
    } else {
      scanner_display = (
        <Fragment>
          <p>
            <b>No event profile is active.</b> Scan a setup QR Code or visit the
            event setup link to continue.
          </p>
        </Fragment>
      );
    }

    return (
      <div className="container">
        {scanner_display}

        <br />

        {this.props.scanned && (
          <div
            className="card bg-white shadow rounded-0 p-3 mx-auto"
            style={{ border: `5px solid ${border_color}` }}
          >
            {ticket_display}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scanned: state.scanner.scanned,
  scanned_id: state.scanner.scanned_id,
  loading: state.scanner.loading,
  display_ticket: state.scanner.display_ticket,
  current_ticket: state.scanner.current_ticket,
  display_profile: state.profile.display_profile,
  profile: state.profile.current_profile,
  profile_id: state.profile.profile_id,
});

export default connect(mapStateToProps, { get_ticket, get_profile })(
  ScannerPage
);
